import React from "react";
import BuiltWithWorkplay from "../components/BuiltWithWorkplay";

const { REACT_APP_WORKPLAY_URL, REACT_APP_S3_URL } = process.env;
const LinkNotFoundImg = `${REACT_APP_S3_URL}/assets/page-not-found.jpg`;

interface LinkActiveOrExpiredPageProps {
  message: string;
}

const LinkActiveOrExpiredPage: React.FC<LinkActiveOrExpiredPageProps> = ({
  message,
}) => {
  return (
    <section className="absolute bottom-0 flex h-screen w-screen items-center justify-center border-0 bg-white">
      <div className="flex flex-col items-center justify-center">
        <img
          className="m-auto h-[220px] sm:h-[400px]"
          src={LinkNotFoundImg}
          alt="work in progress"
        />
        <h1 className="mt-4 max-w-[80%] text-center font-medium sm:max-w-[60%] sm:text-lg lg:text-xl">
          {message}
        </h1>

        <p className="m-auto -mt-4 w-[280px] text-center text-[15px] font-medium text-gray-600 lg:w-full lg:text-[17px]">
          <br /> Please{" "}
          <a
            className="text-blue-800 underline"
            href={`${REACT_APP_WORKPLAY_URL}/contact`}
          >
            contact us
          </a>{" "}
          for assistance.
        </p>

        <BuiltWithWorkplay />
      </div>
    </section>
  );
};

export default LinkActiveOrExpiredPage;
