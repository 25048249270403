import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { useNavigate, useLocation } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import { CSSTransition } from "react-transition-group";
import { X } from "react-feather";
import { setQuizDetails } from "../store/slices/gameSlice";
import { useLogoSize } from "../hooks/useLogoSize";
import LoadingSpinner from "../components/LoadingSpinner";
import InstructionHeader from "../components/InstructionHeader";
import BuiltWithWorkplay from "../components/BuiltWithWorkplay";
import LogoComponent from "../components/LogoComponent";
import useFetchQuizDetails from "../hooks/useFetchQuizDetails";
import useDynamicStyles from "../hooks/useDynamicStyles";
import { validateEmail, validateName } from "../utils/validation";
import { checkUserEmail } from "../api/checkUserEmailAPI";
import "../styles/pageTransitions.css";
import useGameStyles from "../hooks/useGameStyles";
import LinkActiveOrExpiredPage from "./LinkActiveOrExpiredPage";

const { REACT_APP_S3_URL } = process.env;

const QuizPlayPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const campaignId = queryParams.get("campaignId");
  const userName = useSelector((state: RootState) => state.game.userName);
  const userEmail = useSelector((state: RootState) => state.game.userEmail);
  const [showContent, setShowContent] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const [name, setName] = useState(userName || "");
  const [email, setEmail] = useState(userEmail || "");

  const { quizDetails, loading, status, message } =
    useFetchQuizDetails(campaignId);
  const { backgroundStyle, buttonBackgroundColor } =
    useDynamicStyles(quizDetails);

  const { textStyles } = useGameStyles(quizDetails);

  const logoUrl: string | null =
    quizDetails && quizDetails.logo
      ? `${REACT_APP_S3_URL}/quiz/logo/${quizDetails.logo.image}`
      : null;
  const logoSize = useLogoSize(logoUrl);

  useEffect(() => {
    if (!loading && quizDetails) {
      setShowContent(true);
    }
  }, [loading, quizDetails]);

  useEffect(() => {
    if (quizDetails) {
      dispatch(setQuizDetails(quizDetails));
    }
  }, [quizDetails, dispatch]);

  const handlePlayClick = async () => {
    if (quizDetails.displayLeaderboard) {
      if (!name || !email) {
        toast.error("Please fill in all fields.", {
          style: {
            fontFamily: textStyles?.fontFamily,
            borderRadius: "30px",
          },
        });
        return;
      }

      if (!validateEmail(email)) {
        toast.error("Please enter a valid email address.", {
          style: {
            fontFamily: textStyles?.fontFamily,
            borderRadius: "30px",
          },
        });
        return;
      }

      if (!validateName(name)) {
        toast.error("Name should not contain numbers.", {
          style: {
            fontFamily: textStyles?.fontFamily,
            borderRadius: "30px",
          },
        });
        return;
      }
    }
    try {
      const response = await checkUserEmail(campaignId!, email);
      if (response.status === 200) {
        sessionStorage.setItem("quizUserName", name);
        sessionStorage.setItem("quizUserEmail", email);
        navigate(`/game?campaignId=${campaignId}`, { replace: true });
        window.location.reload();
      }
    } catch (error: any) {
      console.error("Error:", error);
      if (error.response && error.response.status === 400) {
        setName("");
        setEmail("");
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    }
  };
  if (status === 200 && message === "event yet to start") {
    return (
      <LinkActiveOrExpiredPage message="This link is not yet active. Please check the project configuration or adjust the dates." />
    );
  }

  if (status === 200 && message === "link currently unavailable") {
    return (
      <LinkActiveOrExpiredPage message="This link is currently unavailable. Please activate your project to continue." />
    );
  }

  if (status === 400 && message === "event link expired") {
    return (
      <LinkActiveOrExpiredPage message="This link has expired. Please check the project configuration or adjust the dates." />
    );
  }

  if (status === 400 && message === "event not available") {
    return (
      <LinkActiveOrExpiredPage message="This link is no longer available. Renew your plan to restore access." />
    );
  }

  if (status === 400 && message === "invalid link") {
    return (
      <LinkActiveOrExpiredPage message="Upgrade to gain access to this link." />
    );
  }

  if (loading || !quizDetails) {
    return (
      <LoadingSpinner
        backgroundColor={quizDetails?.background?.secondaryBColor || "#ffffff"}
        secondaryBackgroundColor={
          quizDetails?.background?.primaryBColor || "#ffffff"
        }
        isImageBackground={Boolean(quizDetails?.background?.image)}
      />
    );
  }

  const secondaryBColor = quizDetails?.background?.secondaryBColor;
  const backgroundWithOpacity = secondaryBColor
    ? `rgba(${parseInt(secondaryBColor.slice(1, 3), 16)}, ${parseInt(
        secondaryBColor.slice(3, 5),
        16,
      )}, ${parseInt(secondaryBColor.slice(5, 7), 16)}, 0.7)`
    : "#ffffff";

  return (
    <CSSTransition
      in={showContent}
      timeout={500}
      classNames="fade"
      unmountOnExit
    >
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-white">
        <Toaster />
        <div
          className="relative flex h-full w-full flex-col items-center justify-center p-8"
          style={backgroundStyle}
        >
          <InstructionHeader
            onShowInstructions={() => setShowInstructions(true)}
            showInstructionsButton={
              quizDetails?.displayInstructions &&
              quizDetails.instructions.length > 0
            }
          />
          {showInstructions && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div
                className="relative w-[90%] max-w-md rounded-lg p-6"
                style={{
                  fontSize:
                    `${Math.round(quizDetails?.textSize * 0.72)}px` || "16px",
                  fontFamily: quizDetails?.fontFamily || "Arial, sans-serif",
                  backgroundColor: quizDetails?.background?.image
                    ? `rgba(${parseInt(
                        quizDetails.background.secondaryBColor.slice(1, 3),
                        16,
                      )}, ${parseInt(
                        quizDetails.background.secondaryBColor.slice(3, 5),
                        16,
                      )}, ${parseInt(
                        quizDetails.background.secondaryBColor.slice(5, 7),
                        16,
                      )})`
                    : "#facc15",
                  maxHeight: "80vh",
                  overflowY: "auto",
                }}
              >
                <button
                  className="absolute right-3 top-3 rounded-full bg-white bg-opacity-80 p-1 text-gray-800 hover:bg-opacity-60"
                  onClick={() => setShowInstructions(false)}
                >
                  <X size={16} />
                </button>
                <h2
                  className="my-3 text-center text-xl font-bold"
                  style={{
                    fontFamily: quizDetails?.fontFamily,
                    fontSize: textStyles.descriptionSize,
                  }}
                >
                  Instructions
                </h2>
                <ul className="list-disc pl-5 text-left">
                  {quizDetails?.instructions?.map(
                    (instruction: string, index: number) => (
                      <li
                        key={index}
                        className="mb-2 break-words"
                        style={{ fontSize: textStyles.instructionSize }}
                      >
                        {instruction}
                      </li>
                    ),
                  )}
                </ul>
              </div>
            </div>
          )}

          <div
            className="flex min-w-[310px] items-center justify-center rounded-xl px-8 py-10 lg:min-w-[400px] lg:p-10"
            style={{
              ...(quizDetails?.background?.image && {
                backgroundColor: `rgba(${parseInt(
                  quizDetails.background.secondaryBColor.slice(1, 3),
                  16,
                )}, ${parseInt(
                  quizDetails.background.secondaryBColor.slice(3, 5),
                  16,
                )}, ${parseInt(
                  quizDetails.background.secondaryBColor.slice(5, 7),
                  16,
                )}, 0.7)`,
              }),
              fontSize: textStyles?.inputSize,
              fontFamily: textStyles?.fontFamily,
              outlineColor: quizDetails?.background?.secondaryBColor,
            }}
          >
            <div>
              {quizDetails?.logo && (
                <LogoComponent
                  specification={{
                    logo: { previewUrl: logoUrl },
                    websiteUrl: quizDetails.logo.websiteUrl,
                  }}
                  logoSize={logoSize}
                />
              )}

              <h1
                className="mb-4 text-center font-extrabold uppercase tracking-wider"
                style={{
                  fontSize: textStyles?.titleSize,
                  fontFamily: textStyles?.fontFamily,
                }}
              >
                {quizDetails.title}
              </h1>

              <p
                className="mx-auto text-center lg:max-w-96"
                style={{
                  fontSize: textStyles?.descriptionSize,
                  fontFamily: textStyles?.fontFamily,
                }}
              >
                {quizDetails.description}
              </p>
              {quizDetails.leaderboard && (
                <div className="mx-auto mt-4 space-y-4 lg:w-[75%]">
                  <input
                    type="text"
                    placeholder="Enter your name"
                    className="w-full rounded-full border px-5 py-2.5"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    style={{
                      fontSize: textStyles?.inputSize,
                      fontFamily: textStyles?.fontFamily,
                      outlineColor: quizDetails.background.image
                        ? quizDetails.background.primaryBColor
                        : "#facc15",
                    }}
                  />
                  <input
                    type="email"
                    placeholder="Enter your email"
                    className="w-full rounded-full border px-5 py-2.5"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{
                      fontSize: textStyles?.inputSize,
                      fontFamily: textStyles?.fontFamily,
                      outlineColor: quizDetails.background.image
                        ? quizDetails.background.primaryBColor
                        : "#facc15",
                    }}
                  />
                </div>
              )}
              <button
                className={`mx-auto mt-8 rounded-full px-10 py-2.5 font-bold uppercase shadow-md hover:opacity-80 ${
                  quizDetails?.background?.image ? "" : "bg-yellow-400"
                }`}
                style={{
                  ...(quizDetails?.background?.image && {
                    backgroundColor: buttonBackgroundColor,
                  }),
                  fontSize: textStyles?.buttonSize,
                  fontFamily: textStyles?.fontFamily,
                }}
                onClick={handlePlayClick}
              >
                play
              </button>
            </div>
          </div>
          <BuiltWithWorkplay />
        </div>
      </div>
    </CSSTransition>
  );
};

export default QuizPlayPage;
